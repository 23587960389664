.background_secondary {
    background-color: #BDE5E4;
}

.plain {
    margin-bottom: 0px;
}

.no-button-style {
  border: 0;
  border-radius: 10px;
  background-color: #FFFFFF;

  &:hover {
    background: #CBEDEC;
    color: #101F1E;
  }
}

.login-button {
    /* Primary */
    border-radius: 52px;

    /* Register */
    width: 249px;
    height: 60px;
}

.modal {
  display: block;
}

.primary {
    background: #3DC4C0;
    color: #FFFFFF;
}

.secondary {
    background: #CBEDEC;
    color: #101F1E;
}

.welcome-page-container {
    width: 450;
    height: 900;
}

.filament {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 53px;
    line-height: 43px;
    letter-spacing: -0.04em;
    color: #3DC4C0;
    margin-bottom: 20px;
}

.content-column {
    max-width: 600px;
}

.modal {
    width: 500px;
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  
    &.red {
      background: black;
      color: red;
    }
    &.blue {
      background: blue;
      color: red;
    }
  
    &.off {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transform: scale(0.33);
      box-shadow: 1rem 0 0 rgba(black, 0.2);
    }
    @supports (offset-rotate: 0deg) {
      // OLD
      // offset-rotation: 0deg;
      // NEW
      offset-rotate: 0deg;
      offset-path: path("M 250,100 S -300,500 -700,-200");
      &.off {
        offset-distance: 100%;
      }
    }
    @media (prefers-reduced-motion) {
      offset-path: none;
    }
    h2 {
      border-bottom: 1px solid #ccc;
      padding: 1rem;
      margin: 0;
    }
    .content {
      padding: 1rem;
    }
    .actions {
      border-top: 1px solid #ccc;
      background: #eee;
      padding: 0.5rem 1rem;
      button {
        border: 0;
        background: #78f89f;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        line-height: 1;
      }
    }
  }

  .card {
    margin-bottom: 20px;
  }